import React, { useState } from 'react';
import { TextField as MUITextField, Theme, useMediaQuery } from '@mui/material';
import { IQuestion } from '../../../redux/api/surveysApiSlice';
import { TSurveyAnswer } from '../types';

type TTextFieldProps = {
  onChange: (result: string) => void;
  item: IQuestion;
  data?: TSurveyAnswer;
}

export default function TextField ({
  data: { result: defaultValue } = { result: '', id: '' },
  item,
  onChange,
}: TTextFieldProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [value, setValue] = useState(defaultValue);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <MUITextField
      value={value}
      placeholder={item.placeholder || ''}
      multiline
      onChange={handleChange}
      sx={{ width: isDesktop ? '50%' : '100%' }}
    />
  );
}

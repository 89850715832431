import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import {
  Breadcrumbs,
  Container,
  ContentWrapper,
  IBreadcrumb,
  Stepper,
  Title,
} from '../../components';
import NavBar from '../../components/NavBar';
import SettingsPage from '../../pages/SettingsPage/SettingsPart';
import { useGetAllParentGoalsQuery } from '../../redux/api/goalsApiSlice';
import { useGenerateSessionIdQuery } from '../../redux/api/historyApiSlice';
import {
  useUpdateOnboardingSilentMutation,
} from '../../redux/api/settingsApiSlice';
import GoodJob from '../Path/GoodJob';
import Path from '../Path/Path';
import { Survey, LockableSurveyWrapper } from './Survey';
import Player from '../Player';
import AddFavorites from '../Path/AddFavorites';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useLoadFavoritesQuery,
} from '../../redux/api/favoritesApiSlice';
import {
  ISurveyMount,
  ISurvey,
  useGetSurveysMutation, useGetHooksQuery,
} from '../../redux/api/surveysApiSlice';
import { TChainPartHandle, THook, TPathSource, TSurveyContext } from './types';
import useLocalStorageState from 'use-local-storage-state';

type TChainSurveyState = 'first-login' | 'pre-session' | 'post-session';
type TChainRegularState =
  'settings'
  | 'duration'
  | 'player'
  | 'favorites'
  | 'good-job';

const groupBy = <T, K extends keyof T>(array: T[], key: K): Record<string, T[]> => {
  return array.reduce((acc: Record<string, T[]>, curr: T) => {
    const fixedKey = (typeof curr[key] !== 'string'
      ? '' + curr[key]
      : curr[key]) as string;
    if (!acc[fixedKey]) {
      acc[fixedKey] = [];
    }
    acc[fixedKey].push(curr);
    return acc;
  }, {});
};

const mapSurveyToSteps = (
  survey: ISurvey,
  type: TChainSurveyState,
  mount: ISurveyMount,
): TPlannedChain => {
  return Object.entries(groupBy(survey.questions, 'step')).map(([step, questions]) => ({
    type,
    mountId: mount._id,
    surveyId: survey._id,
    internalStep: +step,
    isSkippable: questions.every(q => q.skippable),
  }));
};

const checkSurveyMountConditions = (
  mount: ISurveyMount,
  context: TSurveyContext,
): boolean => {
  if (!mount.conditions) {
    return true;
  }
  if (!mount.conditions.goal) {
    return true;
  }
  return mount.conditions.goal.includes(context.goal);
};

const mapSurveyMountToSteps = (
  hook: THook,
  mounts: Record<THook, ISurveyMount[]>,
  surveys: ISurvey[],
  context: TSurveyContext,
  type: TChainSurveyState,
): TPlannedChain => {
  return mounts[hook].reduce<TPlannedChain>((steps, mount) => {
    if (!checkSurveyMountConditions(mount, context)) {
      return steps;
    }
    const idToSearch = hook === 'End player' && mount.type === 'related'
      ? mount.endSurveyId
      : mount.startSurveyId;
    const survey = surveys.find(survey => survey._id === idToSearch);
    if (!survey) {
      return steps;
    }
    return [...steps, ...mapSurveyToSteps(survey, type, mount)];
  }, []);
};

const isNextLocked = (step: TPlannedStep) => {
  return step.type === 'first-login'
    || step.type === 'favorites'
    || step.type === 'pre-session'
    || step.type === 'post-session';
};


type TPlannedSurveyStep = {
  type: TChainSurveyState;
  surveyId: string;
  mountId: string;
  isSkippable: boolean;
}

type TPlannedRegularStep = {
  type: TChainRegularState;
}

type TPlannedStep = (TPlannedRegularStep | TPlannedSurveyStep) & {
  internalStep: number;
};

type TPlannedChain = TPlannedStep[];

type TSurveyChainParams = {
  goalId: string;
  source: TPathSource;
  pathId: string;
}

export default function SurveysChain({ startState, hooks = [] }: { startState: THook, hooks?: ISurveyMount[] }) {
  const {
    goalId = '',
    source = 'pick_for_me',
    pathId: basePathId,
  } = useParams<TSurveyChainParams>();
  const [localSurveys =[]] = useLocalStorageState<ISurvey[]>('surveys');


  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const [surveys, setSurveys] = useState<ISurvey[]>(localSurveys);
  const [pathId, setPathId] = useState(basePathId);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [canProceed, setCanProceed] = useState(false);
  const [isSkippable, setIsSkippable] = useState(false);

  const { data: mounts = hooks } = useGetHooksQuery(undefined, {
    skip: !!hooks.length
  });
  const { data: favorites = [] } = useLoadFavoritesQuery();
  const {
    data: sessionId,
    refetch: refreshSession,
  } = useGenerateSessionIdQuery({ pathId: pathId || '' }, {
    skip: !goalId || !pathId,
  });
  const [updateOnboarding] = useUpdateOnboardingSilentMutation();
  const [getSurvey] = useGetSurveysMutation();

  const navigate = useNavigate();

  const handleRef = useRef<TChainPartHandle>(null);

  const isInFavorites = (pathId: string) => {
    return favorites.find(fav => fav.path === pathId);
  };

  const mountsMap = useMemo(
    () => mounts.reduce(
      (acc, mount) => {
        if (mount.startHook) {
          acc[mount.startHook].push(mount);
        }
        if (mount.endHook) {
          acc[mount.endHook].push(mount);
        }
        return acc;
      },
      {
        ['Start player']: [],
        ['End player']: [],
        ['First login']: [],
      } as Record<THook, ISurveyMount[]>,
    ),
    [mounts],
  );

  const chainPlan = useMemo(() => {
    const planned: TPlannedChain = [];
    const context = {
      goal: goalId,
    };
    switch (startState) {
      case 'First login':
        planned.push(...mapSurveyMountToSteps(
          'First login',
          mountsMap,
          surveys,
          context,
          'first-login',
        ));
        planned.length && planned.push({
          type: 'settings',
          internalStep: 1,
        });
        break;
      case 'Start player':
        if (!basePathId) {
          planned.push({
            type: 'duration',
            internalStep: 1,
          });
        }
        planned.push(...mapSurveyMountToSteps(
          'Start player',
          mountsMap,
          surveys,
          context,
          'pre-session',
        ));
        planned.push({
          type: 'player',
          internalStep: 1,
        });
        planned.push(...mapSurveyMountToSteps(
          'End player',
          mountsMap,
          surveys,
          context,
          'post-session',
        ));
        if (!isInFavorites(pathId || '')) {
          planned.push({
            type: 'favorites',
            internalStep: 1,
          });
        }
        planned.push({
          type: 'good-job',
          internalStep: 1,
        });
    }
    return planned;
  }, [startState, mounts, surveys, pathId]);

  const getLastDivider = () => {
    for (let idx = stepIndex - 1; idx > 0; idx--) {
      if (chainPlan[idx].type === 'player') {
        return idx + 1;
      }
    }
    return 0;
  };

  const getNextDivider = () => {
    const lastDividerIndex = getLastDivider();
    for (let idx = lastDividerIndex + 1; idx < chainPlan.length; idx++) {
      if (chainPlan[idx].type === 'player') {
        return idx;
      }
    }
    return chainPlan.length;
  };

  const getStepsCount = () => {
    const lastDividerIndex = getLastDivider();
    const nextDividerIndex = getNextDivider();
    return nextDividerIndex - lastDividerIndex + 1;
  };

  const getStepperCurrent = () => {
    const lastDivider = getLastDivider();
    return stepIndex - lastDivider + 1;
  };

  const getStepperStepsCount = () => {
    return getStepsCount() - 1;
  };

  const currentStep = chainPlan[stepIndex];

  const { data: parentGoals } = useGetAllParentGoalsQuery(goalId!, {
    skip: !goalId || currentStep?.type === 'player',
  });

  useEffect(() => {
    if (mounts?.length) {
      const idsSurveys = mounts?.reduce((acc, hook) => {
        if (hook.endSurveyId) {
          acc.push(hook.startSurveyId, hook.endSurveyId);
        }
        else {
          acc.push(hook.startSurveyId);
        }
        return acc;
      }, [] as string[]);

      if (idsSurveys) {
        getSurvey({
          ids: idsSurveys,
        })
          .unwrap()
          .then((res) => {
            setSurveys(res);
          });
      }
    }
  }, [mounts]);

  useEffect(() => {
    if (parentGoals) {
      const bc = parentGoals.map((parent) => {
        return { label: parent.name, route: `/goal/${parent._id}` };
      });
      setBreadcrumbs(bc);
    }
  }, [parentGoals]);

  if (!currentStep) {
    return null;
  }

  const getNextButtonTitle = () => {
    const nextStep = chainPlan[stepIndex + 1];
    if (!nextStep) {
      return 'Let’s Start!';
    }
    if (nextStep.type === 'player') {
      return 'Start Your Path';
    }
    return 'Advance';
  };

  const hideStepperButtons = currentStep.type === 'duration';

  const handleStepDone = () => {
    setStepIndex(prev => prev + 1);
  };

  const getSurveyById = (id: string) => {
    return surveys.find(s => s._id === id) as ISurvey;
  };

  const handleNextStep = () => {
    const nextStep = stepIndex + 1;
    if (handleRef.current) {
      handleRef.current.nextStep(currentStep.internalStep + 1);
      setStepIndex(nextStep);
    }
    else {
      setStepIndex(nextStep);
    }
    if (chainPlan[nextStep]?.type === 'settings') {
      updateOnboarding({
        demographicSurvey: true,
      });
    }
  };

  const handlePrevStep = () => {
    const prevStep = stepIndex - 1;
    setStepIndex(prevStep);
  };

  const handleRestart = () => {
    setStepIndex(0);
    refreshSession();
  };

  const handleNavigateBack = () => {
    if (getStepperCurrent() > 1) {
      handlePrevStep();
    }
    else {
      if (source === 'pick_for_me') {
        navigate('/');
      }
      else {
        navigate(-1);
      }
    }
  };

  const isStepperNeeded = () => {
    return currentStep.type !== 'good-job';

  };

  const isStepsShouldBeShowed = () => {
    if (getStepperStepsCount() < 2) {
      return false;
    }
    if (currentStep.type === 'favorites' && getStepperStepsCount() < 3) {
      return false;
    }
    return currentStep.type !== 'settings';

  };

  const getStepContent = (step: TPlannedStep) => {
    switch (step.type) {
      case 'first-login':
        return <Survey
          sessionId={sessionId?.sessionId || ''}
          ref={handleRef}
          survey={getSurveyById(step.surveyId)}
          internalStep={step.internalStep}
          hook='First login'
          mountId={step.mountId}
          onCanProceed={setCanProceed}
          onCanSkip={setIsSkippable}
        />;
      case 'settings':
        return <SettingsPage
          ref={handleRef}
          onlyBody
        />;
      case 'duration':
        return <Path
          goalId={goalId}
          onDone={(pathId: string) => {
            setStepIndex(prev => prev + 1);
            setPathId(pathId);
          }}
        />;
      case 'pre-session':
        return <Survey
          sessionId={sessionId?.sessionId || ''}
          ref={handleRef}
          survey={getSurveyById(step.surveyId)}
          internalStep={step.internalStep}
          hook='Start player'
          mountId={step.mountId}
          onCanProceed={setCanProceed}
          onCanSkip={setIsSkippable}
        />;
      case 'player':
        return <Player
          pathId={pathId || ''}
          source={source}
          onDone={handleStepDone}
          onRestart={handleRestart}
          sessionId={sessionId?.sessionId || ''}
        />;
      case 'post-session':
        return <Survey
          ref={handleRef}
          sessionId={sessionId?.sessionId || ''}
          survey={getSurveyById(step.surveyId)}
          internalStep={step.internalStep}
          hook='End player'
          mountId={step.mountId}
          onCanProceed={setCanProceed}
          onCanSkip={setIsSkippable}
        />;
      case 'favorites':
        return <AddFavorites
          goal={goalId}
          pathId={pathId || ''}
          sessionId={sessionId?.sessionId || ''}
          ref={handleRef}
          onCanProceed={setCanProceed}
        />;
      case 'good-job':
        return <GoodJob />;
    }
  };

  if (currentStep.type === 'player') {
    return <>{getStepContent(currentStep)}</>;
  }

  const stepper = <Stepper
    showPrev={getStepperCurrent() > 1 && currentStep.type !== 'settings'}
    hideStepperButtons={hideStepperButtons}
    activeStep={getStepperCurrent()}
    stepsCount={getStepperStepsCount()}
    blockNext={isNextLocked(currentStep) && !canProceed}
    onNextStep={handleNextStep}
    onPrevStep={handlePrevStep}
    nextButtonTitle={getNextButtonTitle()}
    prevButtonTitle='Previous Page'
    showSteps={isStepsShouldBeShowed()}
  />;

  if (currentStep
    && (
      currentStep.type === 'first-login'
      || currentStep.type === 'pre-session'
      || currentStep.type === 'post-session'
    )
    && getSurveyById(currentStep.surveyId).lockDisplay
  ) {
    return (
      <LockableSurveyWrapper
        name={getSurveyById(currentStep.surveyId).name}
        blockSkip={!isSkippable}
        onSkip={handleNextStep}
      >
        {getStepContent(currentStep)}
        {isStepperNeeded() ? stepper : null}
      </LockableSurveyWrapper>
    );
  }

  return (
    <Container>
      <NavBar />
      <Title
        onBack={handleNavigateBack}
        title={breadcrumbs[breadcrumbs.length - 1]?.label || 'Welcome'}
        backButton={chainPlan[stepIndex - 1]?.type !== 'player' && startState !== 'First login'}
        rightAction={isSkippable ? {
          label: 'Skip step',
          onClick: handleNextStep,
        } : undefined}
      />
      {startState !== 'First login' && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <ContentWrapper sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        {getStepContent(currentStep)}
        {isStepperNeeded() ? stepper : null}
      </ContentWrapper>
    </Container>
  );
}


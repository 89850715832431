import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import TextField from './components/TextField';
import { RadioField } from './components/RadioField';
import SelectField from './components/SelectField';
import GradeField from './components/GradeField';
import { IQuestion } from '../../redux/api/surveysApiSlice';
import { TSurveyAnswer } from './types';

type SurveyItemProps = {
  onChange: (result: string) => void;
  question: IQuestion;
  data?: TSurveyAnswer;
}

const componentsMapper = {
  grade: GradeField,
  input: TextField,
  radio: RadioField,
  select: SelectField,
} as any;

export default React.memo(function SurveyItem({
  onChange,
  question,
  data,
}: SurveyItemProps) {
  const handleChange = (result: string) => {
    onChange(result);
  };
  const Input = componentsMapper[question.type];
  if (!Input) {
    return null;
  }

  return (
    <Box sx={{ mb: 2 }} key={`${question.id}`}>
      <Box sx={{ paddingBottom: '15px', overflowWrap: 'anywhere' }}>
        <Stack direction={'row'} gap={'8px'}>
          <Typography variant='h3'>{question.text}</Typography>
          {question.skippable && (
            <Typography variant='body1' color='#AAABBA' sx={{whiteSpace: 'nowrap'}}>
              {'(Optional)'}
            </Typography>
          )}
        </Stack>
        {question.description && (
          <Typography variant='body2' sx={{ marginTop: '8px' }}>
            {question.description}
          </Typography>
        )}
      </Box>

      <Input item={question} onChange={handleChange} data={data} />
    </Box>
  );
}, (prev, next) => {
  return prev.question.id === next.question.id;
});


import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import {
  useCreateFavoriteMutation,
  useLoadFavoritesQuery,
} from '../../redux/api/favoritesApiSlice';
import { TChainPartHandle } from '../Surveys/types';

export type TAddFavoritesProps = {
  pathId: string;
  goal: string;
  sessionId: string;
  onCanProceed: (can: boolean) => void;
};

export default React.forwardRef<TChainPartHandle, TAddFavoritesProps>(function AddFavorites(
  { pathId, sessionId, onCanProceed },
  ref,
) {
  const { data: favorites } = useLoadFavoritesQuery();
  const [createFavorite] = useCreateFavoriteMutation();

  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');
  const [name, setName] = useState(`Favorite path ${(favorites || []).length + 1}`);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    onCanProceed(selected !== '');
  }, [selected]);

  const saveFavorites = async () => {
    await createFavorite({
      path: pathId,
      sessionId,
      name,
    });
  };

  useImperativeHandle(ref, () => ({
    nextStep: (step: number) => {
      if (isAgreed) {
        saveFavorites();
      }
      return step;
    },
  }));

  return (
    <>
      <Grid container flexDirection='column'
            sx={{ maxHeight: '564px', marginTop: '24px' }}>
        <Grid item>
          <Typography variant='h2'>Save this path to favorites</Typography>
          <FormControl sx={{ marginTop: '24px' }}>
            <RadioGroup
              value={selected}
              name='radio-buttons-group'
              onChange={(ev) => {
                setSelected(ev.target.value);
                setIsAgreed(ev.target.value === 'true');
              }}
            >
              <FormControlLabel value='true' control={<Radio />} label='Yes' />
              <FormControlLabel value='false' control={<Radio />} label='No' />
            </RadioGroup>
          </FormControl>
          {isAgreed && (
            <Grid item sx={{ marginTop: '30px' }}>
              <TextField
                sx={{
                  width: isDesktop ? '386px' : '100%',
                }}
                inputProps={{
                  maxLength: 30,
                }}
                label='Path name (optional)'
                variant='outlined'
                value={name}
                onChange={(ev) => setName(ev.target.value)}
              />
            </Grid>
          )}
        </Grid>
        <Grid item flexGrow='1'></Grid>
      </Grid>
    </>
  );
});

import { FC, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { IOption, IQuestion } from '../../../redux/api/surveysApiSlice';
import { TSurveyAnswer } from '../types';

interface Props {
  item: IQuestion;
  onChange: (result: string) => void;
  data?: TSurveyAnswer;
}

export const RadioField: FC<Props> = ({
    data: { result: defaultValue } = { result: '', id: '' },
    item,
    onChange,
  }) => {
    const [value, setValue] = useState(defaultValue);
    const [otherValue, setOtherValue] = useState(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue((event.target as HTMLInputElement).value);
      if (event.target.value !== 'other') {
        onChange((event.target as HTMLInputElement).value);
      }
      else {
        onChange(otherValue);
      }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setOtherValue(event.target.value);
      if (value === 'other') {
        onChange(event.target.value);
      }
    };

    const regularValues = item?.questionOptions?.map(opt => opt.value) || [];

    return (
      <FormControl>
        <RadioGroup
          aria-labelledby='controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={value}
          onChange={handleChange}
        >
          {item.questionOptions?.map((option: IOption) => {
            return (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.name}
                componentsProps={{ typography: { variant: 'body1' } }}
              />
            );
          })}
          {item?.other && <FormControlLabel
            key='other'
            value='other'
            control={<Radio checked={!regularValues.includes(+value) && value != ''} />}
            label='Other'
            componentsProps={{ typography: { variant: 'body1' } }}
          />
          }
        </RadioGroup>
        {item?.other && (value === 'other' || (!regularValues.includes(+value) && value != '')) &&
          <TextField multiline onChange={handleInputChange} value={otherValue} />}
      </FormControl>
    );
  }
;


import { THook, TSurveyResult } from '../../features/Surveys/types';
import { apiSlice } from './apiSlice';

export interface ISurveyMount {
  _id: string;
  startHook: THook;
  startSurveyId: string;
  type: 'single' | 'related';
  conditions?: {
    goal?: string[];
  };
  endHook?: THook;
  endSurveyId?: string;
}

export type TQuestionType = 'radio' | 'select' | 'input' | 'grade' | '';

export interface IOption {
  name: string;
  description?: string;
  value: number | null;
}

export interface IQuestion {
  id: string;
  step: number;
  text: string;
  description?: string;
  type: TQuestionType;
  skippable?: boolean;
  other?: boolean;
  placeholder?: string;
  questionOptions?: IOption[];
}
export interface ISurvey {
  _id: string;
  name: string;
  description: string;
  delta?: string;
  lockDisplay: boolean;
  questions: IQuestion[];
  updatedAt?: string;
}
export interface ISurveyResult {
  sessionId: string;
  surveyId: string;
  hook: string;
  result: {
    id: string;
    text: string;
    result: string;
  }[];
}

export const surveysApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHooks: builder.query<ISurveyMount[], void>({
      query: () => ({
        url: 'client/surveys-application',
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 60 * 24
    }),
    loadHooks: builder.mutation<ISurveyMount[], void>({
      query: () => ({
        url: 'client/surveys-application',
        method: 'GET',
      }),
    }),
    getSurveys: builder.mutation<
      ISurvey[],
      {
        ids?: string[];
      }
    >({
      query: (data) => ({
        url: 'client/surveys',
        method: 'POST',
        body: data,
      }),
    }),
    createSurveyResult: builder.mutation<
      ISurveyResult,
      TSurveyResult
    >({
      query: (data) => ({
        url: 'client/surveys-results',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});
export const { useGetHooksQuery,useLoadHooksMutation, useGetSurveysMutation, useCreateSurveyResultMutation } = surveysApi;


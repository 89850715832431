import {Theme, useMediaQuery, useTheme} from '@mui/material';
import {Line, Serie} from '@nivo/line';
import * as React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {useGetDashboardChartDataQuery} from '../../redux/api/dashboardsApiSlice';
import {getFontSize, TCustomTheme} from '../../theme';
import {useLoadSettingsQuery} from "../../redux/api/settingsApiSlice";

export type TDashboardChartProps = {
  id: string;
}

export default function DashboardChart(
  {
    id,
  }: TDashboardChartProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const {data: settings} = useLoadSettingsQuery();
  const {data = {pre: [], post: [], name: ''}} = useGetDashboardChartDataQuery(id);
  const theme = useTheme<TCustomTheme>();

  const chartData: Serie[] = [
    {
      id: settings ? settings.configuration['dashboard-chart-pre-point-title'] : '',
      data: data.pre,
    },
    {
      id: settings ? settings.configuration['dashboard-chart-post-point-title'] : '',
      data: data.post,
    },
  ];
  const minY = Math.min(
    ...data.pre.map(p => p.y),
    ...data.post.map(p => p.y),
  ) - 1;
  const maxY = Math.max(
    ...data.pre.map(p => p.y),
    ...data.post.map(p => p.y),
  ) + 1;
  const xMarks = data.pre.map(p => p.x);
  const xValues = data.pre.map(p => p.x);
  const step = (xValues[1] || 0) - xValues[0];
  xValues.unshift(xValues[0] - step);
  xValues.push(xValues[xValues.length - 1] + step);
  const minX = Math.min(...xValues);
  const maxX = Math.max(...xValues);
  return <AutoSizer style={{minHeight: '350px'}}>
    {({width, height}: { width: number; height: number }) => <Line
      margin={{top: 10, right: 10, bottom: isDesktop ? 90 : 140, left: 60}}
      colors={theme.palette.mode === 'light'
        ? ['#416873', '#5381C4']
        : ['#83f1d8', '#53cdef',]
      }
      pointSymbol={(point) => {
        if (point.borderColor === '#416873' || point.borderColor === '#83f1d8') {
          return (
            <rect
              width={point.size}  // Adjust the width and height as needed
              height={point.size}
              fill={point.color}  // Adjust the fill color as needed
              stroke={point.borderColor}
              strokeWidth={1}
              transform={`translate(-4, -4)`}  // Adjust the translation as needed
            />
          );
        } else {
          return <circle
            r={point.size / 2}
            fill={point.color}  // Adjust the fill color as needed
            stroke={point.borderColor}
            strokeWidth={1}
          />;
        }
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: theme.palette.text.primary,
              fontSize: getFontSize(-2),
            },
          },
          legend: {
            text: {
              fill: theme.palette.text.primary,
              fontSize: getFontSize(),
            },
          },
        },
        tooltip: {
          container: {
            background: theme.palette.background.paper,
          },
        },
      }}
      xScale={{
        type: 'linear',
        min: minX,
        max: maxX,
      }}
      yScale={{
        type: 'linear',
        min: minY,
        max: maxY,
      }}
      gridXValues={xValues}
      gridYValues={Array.from({length: maxY - minY + 1}, (_, i) => minY + i)}
      axisBottom={{
        tickValues: Array.from(xMarks),
        legend: 'Number of Completed Paths',
        legendOffset: 40,
        legendPosition: 'middle',
      }}
      axisLeft={{
        tickValues: Array.from({length: maxY - minY - 1}, (_, i) => minY + i + 1),
        legend: data.name,
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      enableSlices='x'
      width={width}
      height={height}
      data={chartData}
      pointLabelYOffset={-12}
      pointSize={10}
      pointColor={theme.palette.mode === 'light' ? '#FFFFFF' : '#46485B'}
      pointBorderWidth={2}
      pointBorderColor={{from: 'serieColor'}}
      useMesh={true}
    />}
  </AutoSizer>;
}